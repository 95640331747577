<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" class="searchbox" :inline="true">
                            <el-form-item>
                                <el-input v-model="keyWord" type="text" size="small" placeholder="输入评论内容搜索..."
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 80px)" :default-expand-all="true"
                    style="width: 100%">

                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-card class="box-card">
                                <el-descriptions title="" size="mini" :column="2">
                                    <template slot="extra">
                                        <el-button type="primary" size="mini" plain v-if="!scope.row.reply"
                                            @click="ReplyBtn(scope.row)">回复</el-button>
                                        <el-button v-else size="mini" type="info" plain>已回复</el-button>
                                        <el-button type="primary" size="mini" plain @click="ReplyDel(item)">删除
                                        </el-button>
                                    </template>
                                    <el-descriptions-item :label="scope.row.userName">{{ scope.row.contents }}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="评论时间" :contentStyle="{ 'text-align': 'right' }">{{
                                        scope.row.addTime | timefilters
                                    }}</el-descriptions-item>
                                    <el-descriptions-item label="回复" v-if="scope.row.reply">{{ scope.row.reply }}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="回复时间" v-if="scope.row.reply"
                                        :contentStyle="{ 'text-align': 'right' }">{{
                                            scope.row.replyTime | timefilters
                                        }}</el-descriptions-item>
                                </el-descriptions>
                            </el-card>
                        </template>
                    </el-table-column>
                    <el-table-column prop="video.name" label="视频名称">
                    </el-table-column>
                    <el-table-column prop="userName" label="发布人" align="center">
                    </el-table-column>
                    <el-table-column label="发布时间" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.addTime | timefilters }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-dialog title="回复评论" :visible.sync="dialogFormVisible" width="500px">
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item prop="reply">
                    <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.reply" autocomplete="off">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveBtn('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Videos } from "../../../components/HospitalDomain/Videos/Videos"
import bread from "../../../components/common/breadcrumb"

export default {
    name: "Order",
    components: {
        bread
    },
    data () {
        var videos = new Videos(this.TokenClient, this.Services.Healthy)
        return {
            VideosDomain: videos,
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            keyWord: "",
            pickerOptions0: {
                disabledDate (time) {
                    return time.getTime() > Date.now()
                }
            },
            tableData: [],
            dialogFormVisible: false,
            form: {
                id: "",
                reply: ""
            },
            rules: {
                reply: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ]
            }
        }
    },
    mounted () {
        this.getList()
    },
    methods: {
        getList () {
            var _this = this
            _this.tableData = []
            _this.VideosDomain.VideoEvaluateList(_this.keyWord, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results
                    _this.PageIndex = data.data.pageIndex
                    _this.PageSize = data.data.pageSize
                    _this.PageTotal = data.data.pageCount
                    _this.DataTotal = data.data.dataTotal
                },
                function (error) {
                    console.log(error)
                })
        },
        search () {
            this.PageIndex = 1
            this.getList()
        },
        ChangePage (pageIndex) {
            this.PageIndex = pageIndex
            this.getList()
        },
        ReplyBtn (item) {
            this.form.id = item.id
            this.dialogFormVisible = true
        },
        ReplyDel (item) {
            var _this = this
            _this.$confirm('确定删除该评论吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.VideosDomain.RemoveEvaluate(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '删除成功!'
                        })
                        _this.PageIndex = 1
                        _this.getList()
                    },
                    function (err) { console.log(err) })
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })
        },
        saveBtn (formName) {
            var _this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.VideosDomain.VideoEvaluateReplay(_this.form,
                        function (data) {
                            _this.dialogFormVisible = false
                            _this.$refs[formName].resetFields()
                            _this.PageIndex = 1
                            _this.getList()

                        },
                        function (err) {
                            console.log(err)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })

        },
    }
}
</script>

<style scoped>
/*content*/

.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.color-red {
    color: red;
    margin-right: 20px;
}

.color-hui {
    color: #ccc4cc;
    margin-left: 20px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.el-card {
    margin-bottom: 5px;
}
</style>


